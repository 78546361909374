
import {
  SteelspaceModel,
  getModelChecksum,
  getOriginModelId,
  getUserModelsSubscribe,
} from '@/modules/model'
import {
  OnStockUser,
  createOnStockCollaborator,
  createOnStockModel,
  createOnStockProject,
  fetchProjects,
} from '@/modules/onstock'
import { CsBtn, CsFlex, CsSimpleDialog } from '@consteel/csuetify'
import Vue from 'vue'
import { explorerStore, setImportedSteelspaceModelIds } from '../../store/explorer.store'
import ExplorerProjectDataForm from '../Explorer/ExplorerProjectDataForm.vue'
import ImportModelDialog from './ImportModelDialog.vue'
import InviteCollaborator from './InviteCollaborator.vue'

export default Vue.extend({
  name: 'CreateProjectDialog',
  components: {
    CsSimpleDialog,
    CsFlex,
    ImportModelDialog,
    ExplorerProjectDataForm,
    InviteCollaborator,
    CsBtn,
  },
  props: {
    value: { type: Boolean, default: true },
    maxWidth: { type: Number, default: 657 },
  },
  data() {
    return {
      step: 1,
      importModelsDialog: false,
      projectData: {
        name: '',
        description: '',
        valid: true,
      },
      loading: false,
      invitedCollaborators: [] as OnStockUser[],
      importedModelIds: [] as string[],
    }
  },
  watch: {
    value(newVal: boolean) {
      if (newVal) {
        ;(this.$refs.projectDataForm as HTMLFormElement)?.resetValidation()
        ;(
          this.$refs.inviteCollaborator as InstanceType<typeof InviteCollaborator>
        )?.clearNonAddedCollaborators()

        this.step = 1
        this.projectData.name = ''
        this.importedModelIds = []
        this.invitedCollaborators = []
        this.loading = false
        this.projectData.description = ''
      }
    },
  },
  computed: {
    disabledPrimaryButton(): boolean {
      switch (this.step) {
        case 1:
          return !this.projectData.valid

        default:
          return false
      }
    },
    importSourcesText(): string {
      return this.importedModelIds
        .map((importedModelId) => {
          return this.models.find((model) => model.id === importedModelId)
        })
        .map((model) => model?.name)
        .join('\n ')
    },
    models(): SteelspaceModel[] {
      return getUserModelsSubscribe()
    },

    lastStep(): number {
      return 3
    },
    primaryButtonText(): string {
      return this.step === this.lastStep
        ? (this.$t('Create') as string)
        : (this.$t('Next') as string)
    },
    secondaryButtonText(): string {
      return this.step === 1 ? (this.$t('Cancel') as string) : (this.$t('Back') as string)
    },
  },

  methods: {
    handleImportModels(modelIds: string[]): void {
      this.importedModelIds = modelIds
      this.importModelsDialog = false
    },
    handleSecondaryButtonClick(): void {
      this.step > 1 ? (this.step -= 1) : this.close()
    },
    handlePrimaryButtonClick(): void {
      const isValidProjectData = (
        (this.$refs.projectData as Vue)?.$refs.projectDataForm as HTMLFormElement
      )?.validate()

      if (isValidProjectData) this.step < this.lastStep ? (this.step += 1) : this.createProject()
    },
    async createProject(): Promise<void> {
      this.loading = true
      const project = await createOnStockProject(
        this.projectData.name,
        this.projectData.description
      )

      if (!project) {
        console.warn('Error during creating project')
        return
      } //TODO handle error with snackbar
      await Promise.all(
        this.invitedCollaborators.map(async (user) => {
          await createOnStockCollaborator(project.id.toString(), user.id)
        })
      )

      const steelspaceModels = this.models
      await Promise.all(
        this.importedModelIds.map(async (modelId) => {
          await createOnStockModel(
            project.id.toString(),
            getOriginModelId(modelId, steelspaceModels),
            getModelChecksum(modelId, steelspaceModels)
          )
        })
      )

      await fetchProjects()
      setImportedSteelspaceModelIds([
        ...explorerStore.importedSteelspaceModelIds,
        ...this.importedModelIds,
      ])
      this.loading = false
      this.close()
    },

    close(): void {
      this.$emit('input', false)
      this.$emit('close')
    },
  },
})
