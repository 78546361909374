var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        value: _vm.value,
        title: _vm.$t("Create project"),
        persistent: "",
        maxWidth: _vm.maxWidth,
        "fill-width": "",
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "cs-btn",
                    {
                      staticClass: "me-3",
                      attrs: { outlined: "", disabled: _vm.loading },
                      on: { click: _vm.handleSecondaryButtonClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.secondaryButtonText))]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        disabled: !_vm.disabledPrimaryButton,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({ staticClass: "d-inline" }, on),
                                [
                                  _c(
                                    "cs-btn",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled: _vm.disabledPrimaryButton,
                                      },
                                      on: {
                                        click: _vm.handlePrimaryButtonClick,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.primaryButtonText)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Please fill in the required fields.")
                          ) + "!"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", absolute: "", top: "" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cs-create-project-dialog" },
        [
          _c(
            "v-stepper",
            {
              staticClass: "create-stepper elevation-0",
              attrs: { "alt-labels": "", color: "inherit" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "elevation-0" },
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 1, step: "1" } },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("Add project informations"))),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 2, step: "2" } },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("Import models"))),
                      ]),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 3, step: "3" } },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("Invite collaborators"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("explorer-project-data-form", {
                        ref: "projectData",
                        model: {
                          value: _vm.projectData,
                          callback: function ($$v) {
                            _vm.projectData = $$v
                          },
                          expression: "projectData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "cs-flex",
                        { attrs: { vertical: "" } },
                        [
                          _c("div", { staticClass: "font-weight-black" }, [
                            _vm._v(_vm._s(_vm.$t("Imported models"))),
                          ]),
                          _c("v-textarea", {
                            staticClass: "text-body-2",
                            attrs: {
                              value: _vm.importSourcesText,
                              readonly: "",
                              rows: 4,
                              dense: "",
                              "no-resize": "",
                            },
                          }),
                          _c(
                            "cs-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.importModelsDialog = true
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Import from Steelspace")))]
                          ),
                          _c("import-model-dialog", {
                            attrs: { loading: _vm.loading },
                            on: { submit: _vm.handleImportModels },
                            model: {
                              value: _vm.importModelsDialog,
                              callback: function ($$v) {
                                _vm.importModelsDialog = $$v
                              },
                              expression: "importModelsDialog",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "cs-flex",
                        { attrs: { vertical: "" } },
                        [
                          _c("invite-collaborator", {
                            ref: "inviteCollaborator",
                            model: {
                              value: _vm.invitedCollaborators,
                              callback: function ($$v) {
                                _vm.invitedCollaborators = $$v
                              },
                              expression: "invitedCollaborators",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }