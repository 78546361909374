var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-explorer-card",
    _vm._g(
      {
        class: _vm.classes,
        attrs: {
          title: _vm.folderName,
          leftText: _vm.$t("Project"),
          icon: "mdi-folder",
        },
        scopedSlots: _vm._u([
          {
            key: "checkbox",
            fn: function () {
              return [
                _c("v-checkbox", {
                  staticClass: "mt-0",
                  attrs: {
                    readonly: "",
                    color: "#1976D2",
                    ripple: false,
                    "input-value": _vm.selected,
                    "hide-details": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "imageDefaultSlot",
            fn: function () {
              return [
                _c(
                  "cs-flex",
                  {
                    attrs: {
                      align: "center",
                      justify: "center",
                      fillWidth: "",
                      fillHeight: "",
                    },
                  },
                  [_c("v-icon", [_vm._v(" mdi-folder ")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }