var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "pt-3", attrs: { vertical: "" } },
    [
      _c(
        "v-form",
        {
          ref: "projectDataForm",
          attrs: { value: _vm.value.valid, "lazy-validation": "" },
          on: {
            input: ($event) =>
              _vm.$emit("input", { ..._vm.value, valid: $event }),
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "pa-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "font-weight-bold", attrs: { cols: "4" } },
                [_vm._v(_vm._s(_vm.$t("Project name")))]
              ),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.nameRules,
                      filled: false,
                      dense: "",
                      value: _vm.value.name,
                    },
                    on: {
                      input: ($event) =>
                        _vm.$emit("input", { ..._vm.value, name: $event }),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "font-weight-bold", attrs: { cols: "4" } },
                [_vm._v(_vm._s(_vm.$t("Description")))]
              ),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rows: 2,
                      dense: "",
                      rules: _vm.descriptionRules,
                      underlined: "",
                      value: _vm.value.description,
                    },
                    on: {
                      input: ($event) =>
                        _vm.$emit("input", {
                          ..._vm.value,
                          description: $event,
                        }),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }