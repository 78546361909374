var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        title: _vm.$t("Are you sure you want to delete?"),
        maxWidth: 450,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onDialog, attrs: attrsDialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip, attrs: attrsTooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      fab: "",
                                      text: "",
                                      small: "",
                                      disabled: _vm.disabled,
                                    },
                                  },
                                  "v-btn",
                                  { ...attrsDialog, ...attrsTooltip },
                                  false
                                ),
                                { ...onDialog, ...onTooltip }
                              ),
                              [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("Delete")))])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDeleteConfirmationModal,
        callback: function ($$v) {
          _vm.showDeleteConfirmationModal = $$v
        },
        expression: "showDeleteConfirmationModal",
      },
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", absolute: "", top: "" },
          })
        : _vm._e(),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "The file will no longer be available to the people you share it with. This action cannot be undone."
              )
            ) +
            " "
        ),
      ]),
      _c(
        "cs-flex",
        { attrs: { justify: "space-between" } },
        [
          _c(
            "cs-btn",
            {
              staticClass: "cancel",
              attrs: { outlined: "", disabled: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.showDeleteConfirmationModal = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
          ),
          _c(
            "cs-btn",
            { attrs: { loading: _vm.loading }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("Delete")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }