
type KeyValueObject = {
  key: string
  value: unknown
}

import { CsModelHistory, CsHistoryTabs, CsModelInfo } from '@consteel/csuetify'
import Vue from 'vue'
import { formatSize, getHistoryFromCache } from '@/services'
import {
  downloadHistoryItem,
  fetchModelHistoryItemsById,
  historyStore,
  resetHistoryError,
  createHistoryItem,
  deleteHistoryItem,
  editHistoryItem,
  restoreHistoryItem,
  HistoryItem,
  getHistoryLoading,
} from '@/modules/history'
import { SteelspaceModel } from '@/modules/model'
import { SteelspaceFolder } from '@/modules/folder'
import ModelInfo from '../ModelInfo.vue'
import ProjectInfo from '../ProjectInfo.vue'
import DefaultPreviewSvg from '../../assets/DefaultPreview.svg.vue'
import { OnStockModel, OnStockModelStatus, OnStockProject } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'ExplorerInfoTab',
  components: {
    ModelInfo,
    CsModelHistory,
    CsHistoryTabs,
    CsModelInfo,
    ProjectInfo,
    DefaultPreviewSvg,
  },
  data() {
    return {
      historyTab: 0,
    }
  },
  mounted() {
    resetHistoryError()
  },
  props: {
    currFolderIsShared: {
      type: Boolean,
    },
    value: {
      type: Boolean,
    },
    selectedModel: {
      type: Object as () => SteelspaceModel | undefined,
    },
    selectedOnStockModel: {
      type: Object as () => OnStockModel | undefined,
    },
    selectedProject: {
      type: Object as () => OnStockProject | undefined,
    },
    bandwidthLimit: {
      type: Number,
    },
    bandwidthUsage: {
      type: Number,
    },
  },
  computed: {
    historyErrorMessage(): string {
      return this.$t(historyStore.historyErrorMessage).toString()
    },
    historyErrorTitle(): string {
      return this.$t(historyStore.historyErrorTitle).toString()
    },
    historyErrorIcon(): string {
      return historyStore.historyErrorIcon
    },
    historyErrorIconColor(): string {
      return historyStore.historyErrorIconColor
    },
    historyItems(): HistoryItem[] {
      return historyStore.historyItems
    },
    historyLoading(): boolean {
      return getHistoryLoading()
    },
    onStockModelStatusMap(): Record<OnStockModelStatus, string> {
      return {
        WAITING_FOR_WAREHOUSE_OPTIMIZATION: this.$t('Waiting for optimization').toString(),
        WAITING_FOR_PREPARATION: this.$t('Waiting for preparation').toString(),
        WAITING_FOR_APPROVAL: this.$t('Waiting for approval').toString(),
        WAITING_FOR_FINALIZING: this.$t('Waiting for finalization').toString(),
        FINALIZED: this.$t('Finalized').toString(),
      }
    },
  },
  methods: {
    async fetchHistoryItems(): Promise<void> {
      if (!this.selectedModel) return

      await fetchModelHistoryItemsById(this.selectedModel.id)
    },
    handleHistoryErrorCancel(): void {
      resetHistoryError()
    },
    async handleHistoryItemEdit(historyItem: HistoryItem): Promise<void> {
      await editHistoryItem(historyItem)
    },

    async handleHistoryItemDownload(historyItemId: string): Promise<void> {
      await downloadHistoryItem(historyItemId)
    },

    async handleHistoryItemRestore(historyItemId: string): Promise<void> {
      await restoreHistoryItem(historyItemId)
    },

    async handleHistoryItemDelete(historyItemId: string): Promise<void> {
      await deleteHistoryItem(historyItemId)
    },
    async handleHistoryItemOpen(historyItemId: string): Promise<void> {
      const cachedModel = await getHistoryFromCache(historyItemId)
      const historyToOpen = this.historyItems.find((item) => item.id === historyItemId)

      const smadsteelFile = historyToOpen?.historyItemFiles.find(
        (file) => file.extension === 'smadsteel'
      )

      const { size: fileSize } = smadsteelFile || {}

      if (cachedModel || (fileSize && this.bandwidthLimit - this.bandwidthUsage > fileSize)) {
        this.$router.push(`/model/${this.selectedModel?.id}/history/${historyItemId}`)
      } else {
        this.$emit('showBandwidthLimitModal', true)
      }
    },

    async handleHistoryItemCreate({
      title,
      description,
    }: {
      title: string
      description: string
    }): Promise<void> {
      await createHistoryItem(this.selectedModel as SteelspaceModel, title, description)
    },
    getFolderInfo(folder: SteelspaceFolder): KeyValueObject[] {
      return [
        {
          key: this.$t('Created').toString(),
          value: Intl.DateTimeFormat().format(new Date(folder.creationDate)),
        },
        {
          key: this.$t('Parent folder').toString(),
          value: folder.parentName,
        },
        {
          key: this.$t('Size').toString(),
          value: formatSize(folder.size),
        },
      ]
    },
    getFileInfo(file: SteelspaceModel): KeyValueObject[] {
      return [
        {
          key: this.$t('Storage used').toString(),
          value: file.getFormattedFileSize(),
        },
        {
          key: this.$t('Status').toString(),
          value: this.selectedOnStockModel
            ? this.onStockModelStatusMap[this.selectedOnStockModel.status]
            : '-',
        },
        { key: this.$t('Owner').toString(), value: file.originUserData.name },
        {
          key: this.$t('Date of import').toString(),
          value: this.selectedOnStockModel
            ? Intl.DateTimeFormat().format(new Date(this.selectedOnStockModel?.importDate))
            : new Date() +
              ' ' +
              new Date(file.lastModificationDate).toLocaleTimeString(undefined, {
                hour: '2-digit',
                minute: '2-digit',
              }),
        },
        {
          key: this.$t('Last modified').toString(),
          value:
            Intl.DateTimeFormat().format(new Date(file.lastModificationDate)) +
            ' ' +
            new Date(file.lastModificationDate).toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
            }),
        },
      ]
    },
  },
  watch: {
    async selectedModel(): Promise<void> {
      await this.fetchHistoryItems()
    },
  },
})
