
import Vue from 'vue'
import { CsTooltipAvatar, CsFlex } from '@consteel/csuetify'
import { getProjectStatus } from '@/modules/onstock'
import ExplorerManageCollaborators from './Explorer/ExplorerManageCollaborators.vue'
import ExplorerEditProject from './Explorer/ExplorerEditProject.vue'
import { OnStockProject } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'CsProjectInfo',
  components: {
    ExplorerManageCollaborators,
    CsTooltipAvatar,
    CsFlex,
    ExplorerEditProject,
  },
  props: {
    disabledEdit: {
      type: Boolean,
      default: false,
    },
    disabledManageCollaborators: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object as () => OnStockProject,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    owner: {
      type: Object,
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    creationDate(): string {
      return Intl.DateTimeFormat().format(new Date(this.project?.creationDate))
    },
    getProjectStatus(): string {
      return getProjectStatus(this.project.models)
    },
  },
})
