
import Vue from 'vue'
import { CsFlex, CsTooltipAvatar } from '@consteel/csuetify'
import { ModelUserData } from '@/modules/model'
import { OnStockModel } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'ModelInfo',
  components: {
    CsFlex,
    CsTooltipAvatar,
  },
  props: {
    onStockModel: {
      type: Object as () => OnStockModel,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    thumbnail: {
      type: String,
    },
    owner: {
      type: Object,
    },
    shares: {
      type: Array,
      default: () => [],
    },
    infos: {
      type: Array,
      default: () => [] as { key: string; value: string }[],
    },
    description: {
      type: String,
      default: '',
    },
    descriptionCaption: {
      type: String,
      default: 'Description',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    shareDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCollaboratorRoles(user: ModelUserData): string {
      const collaborator = this.onStockModel.project.collaborators.find(
        (collaborator) => collaborator.email === user.email
      )
      return collaborator?.roles?.join(', ') || ''
    },
  },
})
