
import { OnStockProject } from '@/modules/graphql/graphql.types'
import { createOnStockModel, fetchProjects } from '@/modules/onstock'
import Vue from 'vue'
import { getModelChecksum, getOriginModelId, getUserModelsSubscribe } from '../../modules/model'
import { explorerStore, setImportedSteelspaceModelIds } from '../../store/explorer.store'
import ImportModelDialog from '../CreateProject/ImportModelDialog.vue'

export default Vue.extend({
  name: 'ExplorerImportModels',
  components: {
    ImportModelDialog,
  },
  data() {
    return {
      loading: false,
      importModelsDialog: false,
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object as () => OnStockProject,
    },
  },
  methods: {
    async handleImportModels(importModelIds: string[]) {
      this.loading = true

      if (!this.project?.id) {
        console.warn('projectId is not given')
        return
      } //TODO handle error with snackbar
      const newModelIds = importModelIds.filter(
        (importModelId) =>
          !this.project?.models.find((model) => importModelId == model.steelspaceId)
      )

      const steelspaceModels = getUserModelsSubscribe()
      await Promise.all(
        newModelIds.map(async (modelId) => {
          try {
            await createOnStockModel(
              this.project!.id.toString(),
              getOriginModelId(modelId, steelspaceModels),
              getModelChecksum(modelId, steelspaceModels)
            )
          } catch (error: any) {
            console.warn({ error })
          }
        })
      )

      await fetchProjects()
      setImportedSteelspaceModelIds([...explorerStore.importedSteelspaceModelIds, ...newModelIds])
      this.importModelsDialog = false
      this.loading = false
    },
  },
})
