
import Vue from 'vue'
import ViewModeSelect from './ViewModeSelect.vue'
import { CsExplorerBreadcrumbs, CsFlex, CsSelect, CsTextField } from '@consteel/csuetify'
import ExplorerDeleteFile from './ExplorerDeleteFile.vue'
import { Ordering } from '../../services'
import {
  setExplorerOrderBy,
  setExplorerViewMode,
  ExplorerViewMode,
  explorerStore,
  setExplorerSearchKeyword,
} from '@/store/explorer.store'
import ExplorerManageCollaborators from './ExplorerManageCollaborators.vue'
import ExplorerImportModels from './ExplorerImportModels.vue'
import { OnStockModel, OnStockProject } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  components: {
    ViewModeSelect,
    ExplorerDeleteFile,
    ExplorerImportModels,
    CsExplorerBreadcrumbs,
    CsFlex,
    CsSelect,
    CsTextField,
    ExplorerManageCollaborators,
  },
  props: {
    importDisabled: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object as () => OnStockProject,
    },
    model: {
      type: Object as () => OnStockModel,
    },
    manageCollaboratorsDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    currentPath: {
      type: Array as () => Array<{ text: string; id: string }>,
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
    fileSystemLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importModelsDialog: false,
      orderByOptions: [
        { value: 'alphabetical', text: 'A-Z' },
        { value: 'recent', text: 'Recent' },
      ] as { value: Ordering; text: string }[],
    }
  },
  methods: {
    handleChangeSearchKeyword: setExplorerSearchKeyword,
    handleChangeViewMode: setExplorerViewMode,
    handleChangeOrderBy: setExplorerOrderBy,
    navigate(id: string): void {
      this.$emit('navigate', { folderId: id })
    },
  },
  computed: {
    searchKeyword(): string {
      return explorerStore.searchKeyword
    },
    orderBy(): Ordering {
      return explorerStore.orderBy
    },
    viewMode(): ExplorerViewMode {
      return explorerStore.viewMode
    },
  },
})
